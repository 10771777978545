import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/PrimaryBox';
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with OpenHAB2",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR MQTT Server with OpenHAB2' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/OpenHAB2/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "instar-mqtt-server-with-openhab2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-openhab2",
        "aria-label": "instar mqtt server with openhab2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with OpenHAB2`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-configuration-though-paper-ui"
        }}>{`OpenHAB Configuration though Paper UI`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mqtt-binding"
            }}>{`MQTT Binding`}</a></li>
        </ul>
      </li>
    </ul>
    <PrimaryBox mdxType="PrimaryBox" />
    <h2 {...{
      "id": "openhab-configuration-though-paper-ui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-configuration-though-paper-ui",
        "aria-label": "openhab configuration though paper ui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Configuration though Paper UI`}</h2>
    <p>{`The Paper UI is a new interface that helps setting up and configuring your openHAB instance. It does not (yet) cover all aspects, so you still need to resort to textual configuration files.`}</p>
    <p><strong parentName="p">{`Add-on management`}</strong>{`: Easily install or uninstall `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/%7B%7Bdocu%7D%7D/addons/uis/paper/readme.html"
      }}>{`openHAB add-ons`}</a>{`:`}</p>
    <h3 {...{
      "id": "mqtt-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mqtt-binding",
        "aria-label": "mqtt binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding`}</h3>
    <p>{`Go to the `}<strong parentName="p">{`Add-Ons`}</strong>{` Menu and click on the `}<strong parentName="p">{`Bindings Tab`}</strong>{`. We are going to use the MQTT protocol to communicate with our INSTAR IP camera from OpenHAB. To be able to connect to the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker"
      }}>{`INSTAR MQTT Server`}</a>{` we need to install the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/addons/bindings/mqtt/"
      }}>{`MQTT Binding`}</a>{` - make sure that you choose the `}<strong parentName="p">{`Version 2`}</strong>{` (at the moment of writing this is the `}<em parentName="p">{`2.4.0`}</em>{` version).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABRUlEQVQY021OzUoCYRSdZ+iXkFHDRihc9CgaZo6MLSJo06LX6CUKw9RFQS0CrdFdrouWbVxEhA4x48x8P/f7uTGFQtThXjjn3Hu416CVAtvNw14KqibUMsJOQy0r7LRIZBbstLDNGc+IqilsEw62xNE2HBYM4myy4grft6BusdISrZq8uEBq68TZ4KVFVkmRep44Ft9ZZuU1UreIk+PlVebkuJ0xSLch7i9weKMeb5Xbkm5L9BrQb0G/nZCHJvTbMOgItwnuJQw6id89h16D3p0ZClEjjgXGGmNE8i3/QkutlVazqUaUiAZqxbg8deXrezj9nHjjcRyFwCgqqZXUApK90Sg6PmFX11NGaRiikj9lIKLS+u3D8/3A8yae5wVBIKX8fVdrxlEmAIC5nYSllC/PT4HvR1EcJx1zzv/7HRljlNJ5+AvTClDmPYE6ygAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/e4706/OpenHAB2_01.avif 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/d1af7/OpenHAB2_01.avif 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/b6582/OpenHAB2_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/a0b58/OpenHAB2_01.webp 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/bc10c/OpenHAB2_01.webp 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/87ca7/OpenHAB2_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/81c8e/OpenHAB2_01.png 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/08a84/OpenHAB2_01.png 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_01.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now switch to your OpenHAB Inbox:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABNElEQVQY033Lu04CQRQG4CGQqE+iJsp118sb2ADii0g5jsw5A4vxDSys7UyMtFjQII3xASCasI4IWdyowGqAsGMG1sTCePIX37kRt9uZvLS77Udv6t9fndN1IrYimCI6RijAwkEbwu0l3F3BnWUyfH+beoPh50gpdXd5RleJSIQxSSBBMEYwrqEdnztJIBnCVBjNCBph0uv3R53uV7M1m6l65YLurWE2ynMGzxmQ3uD7CX5g8gMTMlHIRrVzpp5nYoX0JnmQsnddcazTV/l8U6sxDmidQNHSKZUDLFwqY9ESGvrgGAQZfAxGk/HYnymlbuv1fP6wKAQi6AAE+DEAP6KUFwrzIZJms/Vk29K2HcepVquUUqGf/y4AYIxxzhctkVK6rut5nu/7jUaDMfbPMyL+3n4DiqTYzdMWpZIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/e4706/OpenHAB2_02.avif 230w", "/en/static/a02009dda8769717752ee1575092368d/d1af7/OpenHAB2_02.avif 460w", "/en/static/a02009dda8769717752ee1575092368d/81d9e/OpenHAB2_02.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/a0b58/OpenHAB2_02.webp 230w", "/en/static/a02009dda8769717752ee1575092368d/bc10c/OpenHAB2_02.webp 460w", "/en/static/a02009dda8769717752ee1575092368d/468b0/OpenHAB2_02.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/81c8e/OpenHAB2_02.png 230w", "/en/static/a02009dda8769717752ee1575092368d/08a84/OpenHAB2_02.png 460w", "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_02.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_02.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`+`}</strong>{` icon to add the MQTT Binding:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAhElEQVQI12P4Xyj0r1j8fxojCGUwE0IsIGWFQv8L+P/n8TL8TWL4l8BwqNz/cZbS/3iGP4mMfxMZCKAkKGL4cXXfr5sH+9Y+PXH00v+be75dPfDj2n6C6PvV/T+u7mf4/vvvr///f+/Z/e/Z0///////9+8/XvDv3/8///5//fXnx+9/AHxQpYPzGwBtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/e4706/OpenHAB2_03.avif 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/d1af7/OpenHAB2_03.avif 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/984a9/OpenHAB2_03.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/a0b58/OpenHAB2_03.webp 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/bc10c/OpenHAB2_03.webp 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/1f8a9/OpenHAB2_03.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/81c8e/OpenHAB2_03.png 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/08a84/OpenHAB2_03.png 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_03.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_03.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Find the MQTT Binding inside the list and select it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "910px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvklEQVQY043Ly2rCQBTG8bPoUhCKPp1oLys34jVdlPo4xo20j6PmzC4gGI29GGeSMHO+MoPuhR9/vrM4hGFTxi0ZNGT0KJO2jFvoP9yJ0CM8E54IL4TX0O4dOh6Vnx/l6t0sZmYxLeNZ5RsFb7feLH1NHJk48l9fcxLA5r95ejwdztku/8t1fZGqsIELbH0dri6crVBnhT0bAQiAy/ZmvdbMxXajOdGsjGKt2CilmTVzOP0ulXJpapNEfr4B+QfXndIQQ4L5WQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/e4706/OpenHAB2_04.avif 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/d1af7/OpenHAB2_04.avif 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/f36e1/OpenHAB2_04.avif 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/a0b58/OpenHAB2_04.webp 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/bc10c/OpenHAB2_04.webp 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/92674/OpenHAB2_04.webp 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/81c8e/OpenHAB2_04.png 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/08a84/OpenHAB2_04.png 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_04.png 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_04.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Add Manually`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAyklEQVQY042NsUoDURBFB4XYGLBI/C3BUlcTrBZF0K0s/AdbsyKIVvkHf0IL8wFWYqMgu6NvZo6+bUQixsuZWxy4jHg1jIMVjtb8eODVuh/2vRqy36Ncolz+G2EsTdl/L1cZC3vCqOtdYWcxwvnm2eXt9OKGyUZbF1oXOtlq6+22LhYiwOzZH19D4S1oggT2P4Tw8Iynj4wl3AjPDc3Jqd3dA1jK8if5MxB8J8JVtVVtVJ+url8eZtFZ5iLzKiLMzLtLEeYevy2/xp/QyUmvc0sG0AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/e4706/OpenHAB2_05.avif 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/d1af7/OpenHAB2_05.avif 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/984a9/OpenHAB2_05.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/a0b58/OpenHAB2_05.webp 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/bc10c/OpenHAB2_05.webp 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/1f8a9/OpenHAB2_05.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/81c8e/OpenHAB2_05.png 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/08a84/OpenHAB2_05.png 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_05.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_05.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And choose the `}<strong parentName="p">{`MQTT Broker`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "911px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz32SS2/bMBCEdeiph7RIf2ECtOihSJMf2rhNrrFdWw4SBRK8JJePJbW7BeVHU8Dp4sNoDpwdSGIj3z/IzTl/ey/X53LzSa7O+OqsmuuP+vWdfm70y5s05bLhi4Yv98ik48X/4ANNXN6m1Z1fzPxyhvMfOL/1ixmt73NbofU/5PY+re6ONKJ1IJTepd6FbcgQchLNqqRVX5ukKgdUtWEKyiWgRQtoDRrIKRSKhWKumsacdjqZKCXLWJQrDZesqui9MdZaY6wDAEQPBoyBUoqICMtxVHWnU/MujLgFGPoBDBBRjBE9+uBjjCGEGGNMqboQxnE8btmHQ/DoENHF6ai+Pcfkq+YQwFiwBqy1iCnnMLUlotpJFIkS0S7Gh3TDY1ERN5/bxQIeHtJmw89deXo6CXdd5bkTylOYRyWyP3+5roN2k/penBNrT2OMIJbHR+5eDs3MfvnbrdbYtm699u2G+16G4SQ8DGP3IjH9fWfrPTi3tQacA+eo/iLOIoUlM+8ok7IqHy/JmElEg/fWWjcRY8hElNL0vepz71NKMY51LVdE/gCEeeszLZQv1wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/e4706/OpenHAB2_06.avif 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/d1af7/OpenHAB2_06.avif 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/e0958/OpenHAB2_06.avif 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/a0b58/OpenHAB2_06.webp 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/bc10c/OpenHAB2_06.webp 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/19a92/OpenHAB2_06.webp 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/81c8e/OpenHAB2_06.png 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/08a84/OpenHAB2_06.png 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_06.png 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_06.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`With the newest Firmware for your INSTAR Full HD camera, you can use the camera as MQTT Broker. For older cameras models please `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#installing-mosquitto"
      }}>{`install a MQTT broker like Mosquito first`}</a>{`. Add your brokers IP address and the broker port - by default this is `}<strong parentName="p">{`1883`}</strong>{`. Note that we are first not going to use `}<a parentName="p" {...{
        "href": "https://community.openhab.org/t/mqtt-binding-and-ssl/40622"
      }}>{`TLS encryption`}</a>{`. We can add it once we are able to establish the connection:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACV0lEQVQ4y52Ty27TQBiFI9FFWyGBxJ6nAwQtpUuWPACvwROwR4gdVdULpYDUhCa22zhOnMR3e8Yz86Gx0/SiIrW19GlsyXN0zn/psPUYtp/A1iPYWsdsP4XNNXjRgZd3xP77qgObq3T06xWSt8/49OE9o3fPYWMFubmOfLN6fzbW6Kizn4TH+3z8PMfZ3UMdfKH8s4PwjhHOEcK9O5VzRAdA+2NU4CHSjCLOUcMJD30aQek4xLM5QkqMqlGOg9EaoxRG349GUI0C8m6PcuCgXA/ljzDQihpzZ1qH9sOK2su3RLi/4MKJjSvrmloppKWuEUI8TFApRTg8Z+x5DROL75NmGVrr/zbgppg9W8EwJO39JRk4SFu/cIryzkCIi5uNcF3XzWkN3CZ+6fDsnNjziMYTyihCFAXSddFx3NbVGMqyZDabkaUpaZoibYkWqFpdd2iKAjWeNE7r8QQZjFHh9LoDDDa8Nu2pjFmib0a2MebDIZGNmxeYPG/QcYKOIoyNbmOWFVQVpiwXtO/IepmkjTydEv36jZxMYDpDh9MGM5ujRwF64JCfdJn1ehR2ToMAHYxbJiHytN+kvKxhOCUKAnKlKGqJMKYFqISgdj1Et0d22ifv95Fn52h/hB76zVJUJz1MUV4K6tmc5Os38t09yt09xP4h0nL4A/F9B9XtLTfnakcvJuBqp1vBOCE+6VLbQa4q9AW2g3ECw3P8uMIdZ1RFTmZrvBil5RwuNq4VTBKm+wdEjksyGJA4LtXQp/RHFP0BteMilCJNErIsW1JVVbNNdnQuHP4DJW4514Alp4IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/e4706/OpenHAB2_07.avif 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/d1af7/OpenHAB2_07.avif 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/ada94/OpenHAB2_07.avif 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/a0b58/OpenHAB2_07.webp 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/bc10c/OpenHAB2_07.webp 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/8d1ba/OpenHAB2_07.webp 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/81c8e/OpenHAB2_07.png 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/08a84/OpenHAB2_07.png 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_07.png 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_07.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your INSTAR Full HD cameras MQTT login is the one you added in the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Menu`}</a>{` (if you are using Mosquitto, use the password you defined when `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#configuring-mosquitto"
      }}>{`setting up your server`}</a>{`). Then save your setting by clicking on the blue confirmation button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "892px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACXElEQVQ4y2WTzW7TQBDHo8IFwQ3EG/BUfEkICSREUasiUNsTF8QrcEVwoMnLcOBDpaWN6pSgJrZje73erx/Mxk7dstIvszs7O/uf9WQQNm4SNm/Bi+vI3G/eJjy/AU/WCE+vEp5egdb2Ed/Sf5XwZA2eXYsMuD9APxpwsn6H8GBAeDhAfNw9J4i916P1hX5MuzdguMWX0XvWhzPmozfovVdUe9tUw5a9berRLtmnl8w+bJJ+3EINlz5BDXcoP7+mHu2gR7sMAOrjPxw93iAvHYWsL+FabIu/hOvNY0J/9Av99h2hqSG4//EW19RYrfBG400Dfulf4UwkJnQhkDYNqq6pW5qmQWsdCSFQFAVpmpLneZyLX2IE50QjMW6p0HtUWVIrFZMZY6KvQwK75N1FYvv7FxJaa0nn82i7DaE7IEOUNcbQH2H1283ahCJZyqiqirIs41wpRdMqkVBJmGVZLFtsXOeyztDaXlQYgo+lSil9hf2hqoqqLOPl1hictRFrxC5VypHVG0pCUSVldwkjbcmNc9TW0ngf5x2+V35PYYi3dYdXb9SpNAZ7kuCSCW4ywSZJRNZemE5Xsas3PJvPWciXlq8nqtuA2NSzGcnXA6ZpTb4w5IWNLApLVjqq41NQKqpclmwtOs1Q8zSisxydZbiyxKsav1hQHR5RjBOqcUIzmaKT3+hkac04IbQdsmybk4Tm9BSyDLIU5nNIWzub4SenuEZjygKnKpxS8Rki0gWXG1v/2Gf65Rtn3w/Ifx5THJ6cczDG7B9S1VLugqIsKauKRqqSf0q/d/8p/Av595MtnpD02gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/e4706/OpenHAB2_08.avif 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/d1af7/OpenHAB2_08.avif 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/33335/OpenHAB2_08.avif 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/a0b58/OpenHAB2_08.webp 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/bc10c/OpenHAB2_08.webp 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/7aa27/OpenHAB2_08.webp 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/81c8e/OpenHAB2_08.png 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/08a84/OpenHAB2_08.png 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_08.png 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_08.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Things Menu`}</strong>{` click on `}<strong parentName="p">{`+`}</strong>{` again - this time to add a `}<strong parentName="p">{`Thing`}</strong>{` that we can use for an `}<em parentName="p">{`PaperUI`}</em>{` button later on:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d568d17f661ff831f1cabc05048220dc/62a6a/OpenHAB2_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEUlEQVQY03WPMUvDUBSFr4NdRPHf+Fvc1BYLBTu4uDr5D1zELrZQ3NycXERTBxcnEa2IMbaxaVPTvLy8vNx7xCgi1X58wxkOBw6pQU/1nkMDnO5JhbC9LFsLUl+U+hKqhOrcP24SaiXUSjQZh2kUJgCf7NpVut9ZMZV5KROXiddmKhvE60SZtYAAyN2b97PD/cb16PyYO03jtOxV23baxmlNmTqt5OIovWyS67r9ft/3/SjR1lp90GCVWCAXJMCk2P2NAAyk/BkoCIIoipRSWWY4tw/dbvDmv7644XD45D/eDW7BDM6/THWiVTwOR8w5hEmpmJm/V0W01llmjTHFnWmk4KdPnuf5BXEc/62yMGbzAdGuXCJMVTdSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d568d17f661ff831f1cabc05048220dc/e4706/OpenHAB2_09.avif 230w", "/en/static/d568d17f661ff831f1cabc05048220dc/d1af7/OpenHAB2_09.avif 460w", "/en/static/d568d17f661ff831f1cabc05048220dc/7f308/OpenHAB2_09.avif 920w", "/en/static/d568d17f661ff831f1cabc05048220dc/9b5ba/OpenHAB2_09.avif 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d568d17f661ff831f1cabc05048220dc/a0b58/OpenHAB2_09.webp 230w", "/en/static/d568d17f661ff831f1cabc05048220dc/bc10c/OpenHAB2_09.webp 460w", "/en/static/d568d17f661ff831f1cabc05048220dc/966d8/OpenHAB2_09.webp 920w", "/en/static/d568d17f661ff831f1cabc05048220dc/e6b69/OpenHAB2_09.webp 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d568d17f661ff831f1cabc05048220dc/81c8e/OpenHAB2_09.png 230w", "/en/static/d568d17f661ff831f1cabc05048220dc/08a84/OpenHAB2_09.png 460w", "/en/static/d568d17f661ff831f1cabc05048220dc/c0255/OpenHAB2_09.png 920w", "/en/static/d568d17f661ff831f1cabc05048220dc/62a6a/OpenHAB2_09.png 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d568d17f661ff831f1cabc05048220dc/c0255/OpenHAB2_09.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`MQTT Binding`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "883px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4fc099f5198b34e84a790032ffdb127b/fe9f1/OpenHAB2_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA0ElEQVQY04XLyQrCMBQF0CC2VBw/0oFGrf6YOK2lWv0FtYl2pRvBIdYmDm1eJOJKUOHwuNzHReAUwTagmYNmXudWCWxTVZCq/oegXdLjRhZaRXAKeowzqoz0/h8kayloWBKbgE2JDWmnJTZl3fotqVuJbaDrfHYedUK3d3a74bgrpkPhDcV08Ml7B+71tUlPzGdI7Y9su2PXx4FdThcRSwCl5Hf6rVTCQtgfEZzYbbEU6zUnVNAV9wknNCKUE8qpvtGr0XwS+f49CGCziSkFxp7ig/YBnD4nuQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fc099f5198b34e84a790032ffdb127b/e4706/OpenHAB2_10.avif 230w", "/en/static/4fc099f5198b34e84a790032ffdb127b/d1af7/OpenHAB2_10.avif 460w", "/en/static/4fc099f5198b34e84a790032ffdb127b/ba3b8/OpenHAB2_10.avif 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4fc099f5198b34e84a790032ffdb127b/a0b58/OpenHAB2_10.webp 230w", "/en/static/4fc099f5198b34e84a790032ffdb127b/bc10c/OpenHAB2_10.webp 460w", "/en/static/4fc099f5198b34e84a790032ffdb127b/d4bc4/OpenHAB2_10.webp 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fc099f5198b34e84a790032ffdb127b/81c8e/OpenHAB2_10.png 230w", "/en/static/4fc099f5198b34e84a790032ffdb127b/08a84/OpenHAB2_10.png 460w", "/en/static/4fc099f5198b34e84a790032ffdb127b/fe9f1/OpenHAB2_10.png 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4fc099f5198b34e84a790032ffdb127b/fe9f1/OpenHAB2_10.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And `}<strong parentName="p">{`Add Manually`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "882px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/51568/OpenHAB2_11.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB1ZI0oC//xAAZEAACAwEAAAAAAAAAAAAAAAAAAgEDExT/2gAIAQEAAQUCwUmlDnQ//8QAGREAAgMBAAAAAAAAAAAAAAAAAAIBA1ET/9oACAEDAQE/AYsbTo2n/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAJREhP/2gAIAQIBAT8BwsOaw//EABUQAQEAAAAAAAAAAAAAAAAAAABB/9oACAEBAAY/Aqqv/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAFhkRExUf/aAAgBAQABPyGewk128bJLH//aAAwDAQACAAMAAAAQ89//xAAXEQEAAwAAAAAAAAAAAAAAAAAAETFh/9oACAEDAQE/EJNm5//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAgEBPxAyJf/EABsQAAIBBQAAAAAAAAAAAAAAAAAB8CExQZHR/9oACAEBAAE/EL82hGtPiJQguH//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809d4fe3481d98c697ffe9ce6ed4374c/e4706/OpenHAB2_11.avif 230w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/d1af7/OpenHAB2_11.avif 460w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/670b7/OpenHAB2_11.avif 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/809d4fe3481d98c697ffe9ce6ed4374c/a0b58/OpenHAB2_11.webp 230w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/bc10c/OpenHAB2_11.webp 460w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/9d646/OpenHAB2_11.webp 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809d4fe3481d98c697ffe9ce6ed4374c/e83b4/OpenHAB2_11.jpg 230w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/e41a8/OpenHAB2_11.jpg 460w", "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/51568/OpenHAB2_11.jpg 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/809d4fe3481d98c697ffe9ce6ed4374c/51568/OpenHAB2_11.jpg",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This time we need to add a `}<strong parentName="p">{`Generic MQTT Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "883px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98d66f54a87d9cc170c3f836284ba745/fe9f1/OpenHAB2_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+UlEQVQY03XLzUrDQBSG4SFpYo1t8SI1cTJNvToJYt1IAmbhSnAhCmmqi5LJn/lr05lzdFBcSIWHj2/zEpifgDuGy1NYTNWyMbBj9b0peBN0NLTJfwh4E6AmeDNklornFlITFjNgloptDc/IAecKkY4OzAA6AtcAqoNrSDqSF/oPakjX/IsdCUcXtkbax7C8uyoDvwr8j/C6Cv32/qaLbrtoeVAbLdvA754f+rcnsk/e86ysqqYsm7ruiqIaBiEECgFSoFTne0FK3A8AiIJnMi8QkexeXvPVKl+vsyRJ47jnvOe8S9Ne+f18y7NtxtvNRtSNiBPJ+Vf8Cde2+PktojJkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98d66f54a87d9cc170c3f836284ba745/e4706/OpenHAB2_12.avif 230w", "/en/static/98d66f54a87d9cc170c3f836284ba745/d1af7/OpenHAB2_12.avif 460w", "/en/static/98d66f54a87d9cc170c3f836284ba745/ba3b8/OpenHAB2_12.avif 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98d66f54a87d9cc170c3f836284ba745/a0b58/OpenHAB2_12.webp 230w", "/en/static/98d66f54a87d9cc170c3f836284ba745/bc10c/OpenHAB2_12.webp 460w", "/en/static/98d66f54a87d9cc170c3f836284ba745/d4bc4/OpenHAB2_12.webp 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98d66f54a87d9cc170c3f836284ba745/81c8e/OpenHAB2_12.png 230w", "/en/static/98d66f54a87d9cc170c3f836284ba745/08a84/OpenHAB2_12.png 460w", "/en/static/98d66f54a87d9cc170c3f836284ba745/fe9f1/OpenHAB2_12.png 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98d66f54a87d9cc170c3f836284ba745/fe9f1/OpenHAB2_12.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am going to create switch that activates or deactivates the alarm areas of an INSTAR IN-8015FHD camera. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Thing ID`}</code>{` represents the MQTT topic that we will have to address to send the command to the selected camera. As `}<strong parentName="p">{`Bridge`}</strong>{` you need to select the MQTT broker you just created. Click on the blue button to confirm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27d201d695c22b3bcb3097b2c811b6fd/bcb8c/OpenHAB2_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABH0lEQVQoz5WQO07DQBCGl3cEojBQUVEk4QpcgzNACFhRrNDFCIkDcBEqrkDPGRCioECKtX6s7d3xPJAhPARYIaNP08x80j+jxG/RaJsDjwOPAo9GOzzcpMDjsxXpKTlpoL8o/qqS/pIMWjJcl/ONuvtrcloP6vGxaqS3IP1lJeHec3hwdX3zcrhLR1vuch/GbRi3XdhxF91Gwq4LO4oYoxxvHyS+u68en0AEqgqQAHEmSkSEUSAFpgIAq0r+XbWMSHGS2aKYy5zKzOycs9Yi4tzyuy/zVy0TYpLEmcnSNLOl/bHB/AffY1Oem7IsyrIEgLcc9AHPfpjWmY5zrU0cG63NJEqiKJ1ESZIWzpG1+ImzaIyrkL5uJmqCEekX0zivJDhsT0wv7f8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27d201d695c22b3bcb3097b2c811b6fd/e4706/OpenHAB2_13.avif 230w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/d1af7/OpenHAB2_13.avif 460w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/401c6/OpenHAB2_13.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27d201d695c22b3bcb3097b2c811b6fd/a0b58/OpenHAB2_13.webp 230w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/bc10c/OpenHAB2_13.webp 460w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/6f82a/OpenHAB2_13.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27d201d695c22b3bcb3097b2c811b6fd/81c8e/OpenHAB2_13.png 230w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/08a84/OpenHAB2_13.png 460w", "/en/static/27d201d695c22b3bcb3097b2c811b6fd/bcb8c/OpenHAB2_13.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27d201d695c22b3bcb3097b2c811b6fd/bcb8c/OpenHAB2_13.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Thing Menu`}</strong>{` click to edit the `}<strong parentName="p">{`Thing`}</strong>{` you just created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "883px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ce1905326c2b2fd6431581ff9f12288/fe9f1/OpenHAB2_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCUlEQVQY04WLTUoDQRCFO8kiILgRRHHvQqJ7j+AxlCATREUiLlzM6AG8hqCgO3OToAslTowg44QQne6a/qnqkjaoiGA+Ph7UK56goyVqL1B7nnZn6HDRH8xxVOFW7T+jKu/UeX9WcKvq9+rhboowi2q8KXhrmk3B2xWRJ+vHZ52H0w2Ol03csPGKTVan6k7WbNIQo7G8vuPXd0MGjC6t0TZkiUYbDb8sQ2pQulRv4xEoKZA9M3LIT71nIiKHZH/K7xczIhIRKInohDy/uO318ixL03QweOr3H7OXrNvvdu5vDBpm9l8AgFJKFnI4zCe9gMur5zxXMlAUhZSFMWHzF+csITrnAGDSfABDAD+OswRqzgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ce1905326c2b2fd6431581ff9f12288/e4706/OpenHAB2_14.avif 230w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/d1af7/OpenHAB2_14.avif 460w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/ba3b8/OpenHAB2_14.avif 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ce1905326c2b2fd6431581ff9f12288/a0b58/OpenHAB2_14.webp 230w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/bc10c/OpenHAB2_14.webp 460w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/d4bc4/OpenHAB2_14.webp 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ce1905326c2b2fd6431581ff9f12288/81c8e/OpenHAB2_14.png 230w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/08a84/OpenHAB2_14.png 460w", "/en/static/3ce1905326c2b2fd6431581ff9f12288/fe9f1/OpenHAB2_14.png 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ce1905326c2b2fd6431581ff9f12288/fe9f1/OpenHAB2_14.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the blue `}<strong parentName="p">{`+`}</strong>{` button to add a `}<strong parentName="p">{`Channel`}</strong>{` to the `}<strong parentName="p">{`Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8676470fecbee59a60212978b3ca7770/bcb8c/OpenHAB2_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRUlEQVQoz42OzUrDUBCFL1ZBUKkKSu1juLN14ZOo0UAFrS2kdeFGV2269mUUwbXiUnElxU03pdg0yf2Zmzt3JInSne3hMMyc4WOG2cY6tsvW27bNTVtfxVYZ62volay3Zb1tbJXwqoztHXtZpFqBzpaotkDNDWoU6XyZkcOoVsCLFTphdMysmyWnLB3drMmrw+go8yEjd5HcAjmMUWf3refc3j1y/8D0Ktqvan9f+1XoVKCzN3W3At3qr/8SRkRDSU9fFBniJq1hQhzJ0GynMCag43E4CaIwFFzEcSylpDmUwgB6NPoOJ5MgCJRSWmsppZBCCCEz5Y1SSvA0NMZMYURUoLjkWmtEtJnnumyJyNIgGDz078FANlmaT/nbMB4HKoz6Q/H8KcFrR9c3FnS6t3YGjBYBQIPiYII4MS+vyfvH/1gO/wD5O/VwPd/XrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8676470fecbee59a60212978b3ca7770/e4706/OpenHAB2_15.avif 230w", "/en/static/8676470fecbee59a60212978b3ca7770/d1af7/OpenHAB2_15.avif 460w", "/en/static/8676470fecbee59a60212978b3ca7770/401c6/OpenHAB2_15.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8676470fecbee59a60212978b3ca7770/a0b58/OpenHAB2_15.webp 230w", "/en/static/8676470fecbee59a60212978b3ca7770/bc10c/OpenHAB2_15.webp 460w", "/en/static/8676470fecbee59a60212978b3ca7770/6f82a/OpenHAB2_15.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8676470fecbee59a60212978b3ca7770/81c8e/OpenHAB2_15.png 230w", "/en/static/8676470fecbee59a60212978b3ca7770/08a84/OpenHAB2_15.png 460w", "/en/static/8676470fecbee59a60212978b3ca7770/bcb8c/OpenHAB2_15.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8676470fecbee59a60212978b3ca7770/bcb8c/OpenHAB2_15.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am going to use an `}<strong parentName="p">{`ON/Off Switch`}</strong>{`. The MQTT topic to go to switch the first alarm area is created by adding the prefix `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` and your cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`LAN MAC Address`}</a>{` (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`00:03:89:88:88:11`}</code>{` - without the colons) to the corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`INSTAR MQTT topic`}</a>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` -> `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{` for the `}<strong parentName="p">{`COMMAND Topic`}</strong>{` and as message payload we need to add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` to either switch the area on or off. To set the state of our switch, we can use the `}<strong parentName="p">{`STATUS Topic`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/status/alarm/area1/enable`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f01220917341a94328afe51cdeda4816/b04e4/OpenHAB2_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA80lEQVQ4y62SiQrEMAhE8/8/2hZ637fLEwxpdxe6YQVRjBlnYtwwDJIkieR5LmmaSpZlsu+7YOd5/uxuWRapqkrGcRTAidM0qW/b5oGfmlvXVZqmuVyO8eM4VJkCdl2nhViwi2QAkflXQN4LwNCiAdFtiyD2fS/zPH9sfjLIcbmua79ZNg3rO1N79LuS+xC/ZZiRs20i34lozBhMH07OOZH+cKgCwrAsS/2PODk1HMY0w97q/AoIEHmmcLhjAkUcSTYplIlxAXCAv5mX3Lat0icP5RINECDro26SLTcSCohM3sYkI4uILGOEAusriuKtD1AAXx2e/fzueJvGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f01220917341a94328afe51cdeda4816/e4706/OpenHAB2_16.avif 230w", "/en/static/f01220917341a94328afe51cdeda4816/d1af7/OpenHAB2_16.avif 460w", "/en/static/f01220917341a94328afe51cdeda4816/dea7a/OpenHAB2_16.avif 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f01220917341a94328afe51cdeda4816/a0b58/OpenHAB2_16.webp 230w", "/en/static/f01220917341a94328afe51cdeda4816/bc10c/OpenHAB2_16.webp 460w", "/en/static/f01220917341a94328afe51cdeda4816/60055/OpenHAB2_16.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f01220917341a94328afe51cdeda4816/81c8e/OpenHAB2_16.png 230w", "/en/static/f01220917341a94328afe51cdeda4816/08a84/OpenHAB2_16.png 460w", "/en/static/f01220917341a94328afe51cdeda4816/b04e4/OpenHAB2_16.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f01220917341a94328afe51cdeda4816/b04e4/OpenHAB2_16.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "893px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/6c745/OpenHAB2_16a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABF0lEQVQoz5WR607EIBCF+/4PZOJDaDQptTXuFv82jVAGhtsApsW6ddfE+DEhkwwHzgzNNE3e+/Ifcs6llLZtG71hrU0pHcrrHmNERLdRBddiRLTWIqLfIaJaDiHgjj9ARF/ieZ6FEMuOlBIRQ1gPOOeklEqp75LWGhEvYmutc+7oudquNm/d/uwZoIZaFg0Aas0BIFH6e2DKOZsSEpkYkcitebSUYy7r2k8fqTYZY43vBxp5PJ1piy0Z49uJOA+IBjHcfGR9eRzHZn56lt3LR8sk6wTrRMsE62B4DSO3i1oAtNbGmDpzuwFal1I45004jOSKEMLW/qoHAGOM9z5472Kku/vzw2NzGe5t/HadECLlbPr+fRg+AVfrfDVZ61FBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ccd21eb759527ad46ce1dd3ace6121ec/e4706/OpenHAB2_16a.avif 230w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/d1af7/OpenHAB2_16a.avif 460w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/796e0/OpenHAB2_16a.avif 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ccd21eb759527ad46ce1dd3ace6121ec/a0b58/OpenHAB2_16a.webp 230w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/bc10c/OpenHAB2_16a.webp 460w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/f0cd5/OpenHAB2_16a.webp 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ccd21eb759527ad46ce1dd3ace6121ec/81c8e/OpenHAB2_16a.png 230w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/08a84/OpenHAB2_16a.png 460w", "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/6c745/OpenHAB2_16a.png 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ccd21eb759527ad46ce1dd3ace6121ec/6c745/OpenHAB2_16a.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the expanded settings we have to set up the Quality-of-Service `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`QoS`}</code>{` to make sure every update is delivered `}<strong parentName="p">{`At-least-once`}</strong>{`. Now click on Save to continue.`}</p>
    <p>{`By clicking on the blue icon in front of the created channel we are now able to link it to a switch in the `}<strong parentName="p">{`OpenHAB PaperUI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "883px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/028906117aab339b6e3202d217a6b5fc/fe9f1/OpenHAB2_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAf0lEQVQI143LMQvCMBCG4fxy/TcdRHByLoIIotBdFxWHUm2GeE0ul/M+UdBR+szv66AKKX3o69Mq5ADAYBjHAShAHAIH3/phc8Z4TrY7rap1c99fi7+1x0vHic3s+fVLRYRzJiL7eM9pvtDJdFZ3ywOEPNEjxsjMnBJnVtU/8wuGaeaO8R7H2QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/028906117aab339b6e3202d217a6b5fc/e4706/OpenHAB2_17.avif 230w", "/en/static/028906117aab339b6e3202d217a6b5fc/d1af7/OpenHAB2_17.avif 460w", "/en/static/028906117aab339b6e3202d217a6b5fc/ba3b8/OpenHAB2_17.avif 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/028906117aab339b6e3202d217a6b5fc/a0b58/OpenHAB2_17.webp 230w", "/en/static/028906117aab339b6e3202d217a6b5fc/bc10c/OpenHAB2_17.webp 460w", "/en/static/028906117aab339b6e3202d217a6b5fc/d4bc4/OpenHAB2_17.webp 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/028906117aab339b6e3202d217a6b5fc/81c8e/OpenHAB2_17.png 230w", "/en/static/028906117aab339b6e3202d217a6b5fc/08a84/OpenHAB2_17.png 460w", "/en/static/028906117aab339b6e3202d217a6b5fc/fe9f1/OpenHAB2_17.png 883w"],
              "sizes": "(max-width: 883px) 100vw, 883px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/028906117aab339b6e3202d217a6b5fc/fe9f1/OpenHAB2_17.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Create new item...`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "882px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52c839832ad30b1c2a45b7230e01cf80/90712/OpenHAB2_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABM0lEQVQoz4WRPU/CUBSG+W0OJi7+An+BkyZGJRIHF+WrSHtOe0svLLiR2AYSd0HBDwYm/QOY3BLa3LaktLltaoTEsTzTWZ73zTmngIgqqvX7+lW5pGrYaXdom7Y3UEoxlwIiAkCtUSveFEcvI497i8WCMWbbNmPMMAxFUfJkRZElqVG+q0zG4/V6zTl3HMffsEMGRF3Do3M4vqx8Tl5FkoRhKITIsiyKIkLIVlYRm7IsSVK1WvuP+2vWEK4lUqrLbx/vQogwDOM4TpIkiiJd1/OaVQBZ159OTqd7+9/PQ9t1fuZzxpjv+57ntVqt3J0BFEIezy6GB4dfwxFfrZzl0nXdIAg457sPhgAypbdEm85mWZbFcZym6XbY1bxBBYBm86HbHQwGvV7Psqx+v2+aZv6ffwE2h2R7WVr8nQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52c839832ad30b1c2a45b7230e01cf80/e4706/OpenHAB2_18.avif 230w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/d1af7/OpenHAB2_18.avif 460w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/670b7/OpenHAB2_18.avif 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52c839832ad30b1c2a45b7230e01cf80/a0b58/OpenHAB2_18.webp 230w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/bc10c/OpenHAB2_18.webp 460w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/9d646/OpenHAB2_18.webp 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52c839832ad30b1c2a45b7230e01cf80/81c8e/OpenHAB2_18.png 230w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/08a84/OpenHAB2_18.png 460w", "/en/static/52c839832ad30b1c2a45b7230e01cf80/90712/OpenHAB2_18.png 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52c839832ad30b1c2a45b7230e01cf80/90712/OpenHAB2_18.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Link`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "765px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89481c2044b936aecf74856b1b9d18bc/bbb77/OpenHAB2_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "102.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKElEQVQ4y52TTVKEMBCFOZcrj+HK87jwCC48k+5hAcwQC6gJELo7nR8rRGWqZpgJfjugX97LKzpDRCGElLJpGiFE13U+mWyapr7vh2E4nU4AQEQ7xESkF6y18ZVLwFrrnAvO0zQppQCAmV0aP+J5nqWU4zhGcWJg51yIrbWeF2LsRGdjTHCOZ6TLInE+i+n9foIYAJRSdIYxJlVsjEFEAMBfzju/cSNrbRavvnU8L2w6E5FSamuCmbXWd2LHictKr2Zexf+rem17HMer327V7pxjzpgZES/974i9d97vj73M28NRf3yGtuNiMDMRIeLfkl7/Z5ZH9fLaPzyGxajruigKIURZlkVR1HV9XKiqKs/z2MhasjHsvXp7l0/P2d6G27YNwQAG8fUNgYiaR+DQnEAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89481c2044b936aecf74856b1b9d18bc/e4706/OpenHAB2_19.avif 230w", "/en/static/89481c2044b936aecf74856b1b9d18bc/d1af7/OpenHAB2_19.avif 460w", "/en/static/89481c2044b936aecf74856b1b9d18bc/23eaf/OpenHAB2_19.avif 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89481c2044b936aecf74856b1b9d18bc/a0b58/OpenHAB2_19.webp 230w", "/en/static/89481c2044b936aecf74856b1b9d18bc/bc10c/OpenHAB2_19.webp 460w", "/en/static/89481c2044b936aecf74856b1b9d18bc/33b41/OpenHAB2_19.webp 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89481c2044b936aecf74856b1b9d18bc/81c8e/OpenHAB2_19.png 230w", "/en/static/89481c2044b936aecf74856b1b9d18bc/08a84/OpenHAB2_19.png 460w", "/en/static/89481c2044b936aecf74856b1b9d18bc/bbb77/OpenHAB2_19.png 765w"],
              "sizes": "(max-width: 765px) 100vw, 765px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89481c2044b936aecf74856b1b9d18bc/bbb77/OpenHAB2_19.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now click on `}<strong parentName="p">{`Control`}</strong>{` to open the `}<strong parentName="p">{`PaperUI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/96658/OpenHAB2_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "106.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEX0lEQVQ4y3WObUwTBxjHL9MvS5bt0ygkajZ0c8nMTEAotLT0endt766963HX3tH30ndpSxGEDgTdnAYYqCxzjmm8wiiE6DTZmFskZmGOl/KyTSWb0ajJ2Ae3OBRQpBR6SzWSTLMnvzx5nt/z//AA6arX1uo3pUOvCL4Ngn/jf/C9lOE5mfEbhMqXhapXAcH+P9gAwbNB8G7MDC9eHYDgBIBUX2ilL5Tqr1p9RqovnOoLr8SDqa/eXzkbTcWD6/I5gFRaWBOEZUF4nBaW1jJ9VciYtCAk08Lqk3VdrpNKZ67AP/N//zo7vXB/buHB3KPF+aXFhVRyOb3yePbu3KPFpbtzf/42e/XBvXsPFx6srSRXk8uryeW1lWQmvDAPLK8+vvXwtvBCzSfXhLRwPzV35+EdIfNI+sUMcPbMua/PfTMwcGZg4Exvb19PTy9/OsafjvX29MR4vof/src7zvOxGN/91D8lxnd3891AQZGkoLAg70nt2LFj+/a3c998IydbJMrKyhZluijr9WxR1jo52aJNmza/tW3b1tytAGzwIpZ6xFqnNNWC5TWQeS9ijcoNVTImLKOfwYRL6LCUDkmZsJQJK7garbtJyfgBU3O/q+ua5dh4+bEJ5ugk3TFOtY4SLaO61jGibZxsT5DtCV3bmL49wbRP0G0Jpn3S2jkVOHmNbugHEBWKwhhndlBYGZwvZxDKY/N+8VEL39j8cajqoNPVZLJ0BiM1FMNiOMcZIUQJq0EFBEKIGtCAKqpMYfnOCp8slbTu1B6V+xhuvDp6m2VnDMaEWj2GIDfL6PMKOQtTlPkEWtYBKw87LPsIQgNolSjnQcirBDQCQiMgMijxU/qRUM2UTjdKkiMYehnTjKmQuKyoVMqQ1ilz4DaKTdjtJ0kSAXBQQ9tLNQm18pJCcVGKnBUH9NqpuvobVsuMzfyLkbnucswG/P2gNG+nirQlTN4rMHyR5Tp0uBLQQijngKhJAh9WKwelcFzc7HHOf3J8MRz8w+O8YTLespr+qqjoKxHvKtCWuX7nPNch+CfKcFSjkgE6FDNCMtwmh+lCcf6WkrzcEIpfONIZj9Qe4SwttKHFwLYY2UaOgBANbmiM1J1wOVusljAEygC1Wq3Y+Z7q3XfIgjyDpMgEg2YccvvcnZ83DA83XB7bPzrR/O1QY8epTwOR3RJQEm2I7Am799aG1CoVoNURRo5zuizVNYHKkN3vM1dW2iL7ol1d7pvT9Myo/uYUfWWs4vjpzw60HlLAqvyiYlFOjs/vI0kCwDAta7YTDBOJRr0Ou9dvrww66g58eIqvHv0e/vECOj1MXBpkmg7vr21uAksVMK4RywrdvgBBkACK4nqGw/V6PrDvEGlzVjjFYGkxzh5ssyWG5EPnpT8PqyZ/YG1eJ8GU75IUG+22cqvN7dmNohiAYjhB0Rqt7gNHMEixLiNbUirP2byFK0f3VOsMTL7VLKmvZVgjpmdYDYartDoUJwhCD8PwvyAqefIoARTdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a38da24b0a6c94fe46287da33ec7d6ae/e4706/OpenHAB2_20.avif 230w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/d1af7/OpenHAB2_20.avif 460w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/5334c/OpenHAB2_20.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a38da24b0a6c94fe46287da33ec7d6ae/a0b58/OpenHAB2_20.webp 230w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/bc10c/OpenHAB2_20.webp 460w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/8e8eb/OpenHAB2_20.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a38da24b0a6c94fe46287da33ec7d6ae/81c8e/OpenHAB2_20.png 230w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/08a84/OpenHAB2_20.png 460w", "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/96658/OpenHAB2_20.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a38da24b0a6c94fe46287da33ec7d6ae/96658/OpenHAB2_20.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our Alarm Areas can now be switched from the OpenHAB `}<strong parentName="p">{`Paper UI`}</strong>{`. Clicking the switch will send the MQTT message to our MQTT server that is going to notify our camera to either switch `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{` area 1 on or off`}</a>{`.`}</p>
    <p>{`All right that's it - the Binding was successfully added and we are now able to control all of our camera functions with the MQTT interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      